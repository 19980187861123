import { Role } from '~/enums/roles'

/**
 * Ritorna TRUE se l'utente loggato può:
 * - vedere tutti i filtri delle task
 * - cambiare visualizzazione tra lista e calendario
 */
export const useIsManager = () => {
  const {data: user} = useAuth()
  return user.value?.roles.some(r => r.id === Role.ADMIN || r.id === Role.MANAGER)
}
